@font-face {
    font-family: 'Mont';
    src: url('./media/fonts/Mont-Black.eot');
    src: local('Mont Black'), local('Mont-Black'),
    url('./media/fonts/Mont-Black.eot?#iefix') format('embedded-opentype'),
    url('./media/fonts/Mont-Black.woff2') format('woff2'),
    url('./media/fonts/Mont-Black.woff') format('woff'),
    url('./media/fonts/Mont-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('./media/fonts/Mont-Bold.eot');
    src: local('Mont Bold'), local('Mont-Bold'),
    url('./media/fonts/Mont-Bold.eot?#iefix') format('embedded-opentype'),
    url('./media/fonts/Mont-Bold.woff2') format('woff2'),
    url('./media/fonts/Mont-Bold.woff') format('woff'),
    url('./media/fonts/Mont-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('./media/fonts/Mont-Heavy.eot');
    src: local('Mont Heavy'), local('Mont-Heavy'),
    url('./media/fonts/Mont-Heavy.eot?#iefix') format('embedded-opentype'),
    url('./media/fonts/Mont-Heavy.woff2') format('woff2'),
    url('./media/fonts/Mont-Heavy.woff') format('woff'),
    url('./media/fonts/Mont-Heavy.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('./media/fonts/Mont-Medium.eot');
    src: local('Mont Medium'), local('Mont-Medium'),
    url('./media/fonts/Mont-Medium.eot#iefix') format('embedded-opentype'),
   url('./media/fonts/Mont-Medium.woff2') format('woff2'),
    url('./media/fonts/Mont-Medium.woff') format('woff'),
    url('./media/fonts/Mont-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('./media/fonts/Mont-Regular.eot');
    src: local('Mont Regular'), local('Mont-Regular'),
    url('./media/fonts/Mont-Regular.eot?#iefix') format('embedded-opentype'),
    url('./media/fonts/Mont-Regular.woff2') format('woff2'),
    url('./media/fonts/Mont-Regular.woff') format('woff'),
    url('./media/fonts/Mont-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

html {
    overflow-y: scroll;
}

html,
body {
    height: 100%;
}

body {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
}

#wrapper {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto -52px; /* the bottom margin is the negative value of the footer's height */
}

#wrapper nav {
    margin-bottom: 0;
    border-radius: 0;
}

footer,
.push {
    height: 52px; /* .push must be the same height as .footer */
}

.footer-content {
    border: 1px solid #e7e7e7;
    border-bottom: none;
    background: #f8f8f8;
    line-height: 49px;
}

.small-muted {
    font-size: 12px;
    color: #aaa;
}

#page-install h1 {
    font-family: "Open Sans", sans-serif;
    font-size: 40px;
    font-weight: 300;
    text-transform: uppercase;
    color: #555;
    margin: 50px 0 30px 0;
}

#page-install h1 a {
    display: inline-block;
    color: #7db4b5;
    border-bottom: 1px solid #7db4b5;
}

#page-install h1 a:hover,
h1 a:focus {
    color: #7db4b5;
    border-color: #fff;
    text-decoration: none;
}

#page-install h2 {
    font-family: "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: 300;
    text-transform: uppercase;
}

#page-install .tooltip {
    min-width: 200px;
}

#page-install .tooltip .tooltip-inner {
    font-size: 12px;
    text-align: left;
    padding: 5px 8px 5px 10px;
}

#page-install .panel .recaptcha-tip {
    margin-bottom: 20px;
}

/*Form Wizard*/
.bs-wizard {
    border-bottom: solid 1px #e0e0e0;
    padding: 0 0 10px 0;
}

.bs-wizard > .bs-wizard-step {
    padding: 0;
    position: relative;
}

.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
    color: #595959;
    font-size: 16px;
    margin-bottom: 5px;
}

.bs-wizard > .bs-wizard-step .bs-wizard-info {
    color: #999;
    font-size: 14px;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
    position: absolute;
    width: 30px;
    height: 30px;
    display: block;
    background: #fbe8aa;
    top: 45px;
    left: 50%;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
    content: " ";
    width: 14px;
    height: 14px;
    background: #fbbd19;
    border-radius: 50px;
    position: absolute;
    top: 8px;
    left: 8px;
}

.bs-wizard > .bs-wizard-step > .progress {
    position: relative;
    border-radius: 0px;
    height: 8px;
    box-shadow: none;
    margin: 20px 0;
}

.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
    width: 0px;
    box-shadow: none;
    background: #fbe8aa;
}

.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {
    width: 100%;
}

.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
    width: 50%;
}

.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {
    width: 0%;
}

.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {
    width: 100%;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {
    background-color: #f5f5f5;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {
    opacity: 0;
}

.bs-wizard > .bs-wizard-step:first-child > .progress {
    left: 50%;
    width: 50%;
}

.bs-wizard > .bs-wizard-step:last-child > .progress {
    width: 50%;
}

.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot {
    pointer-events: none;
}

/*END Form Wizard*/

/* YA-MONEY */
.parsley-error-list {
    color: red;
    padding-left: 0;
}

.parsley-error-list li {
    list-style-type: none;
    font-size: 14px;
}

p.ya-money__example {
    text-align: right;
    font-size: 12px;
    font-style: italic;
}

.ya-money__description p {
    padding-top: 3rem;
    text-align: justify;
}

@media (max-width: 768px) {
    button.btn.btn-primary {
        width: 100%;
    }
}

/* END YA-MONEY */
html {
    overflow-x: hidden;
}

a:hover,
a:focus {
    text-decoration: none;
    color: #fff;
    outline: none;
}

.btn-primary {
    background-color: #5bc0de;
    border-color: #5bc0de;
}

.btn-primary:hover {
    background-color: #5f658a;
    border-color: #5f658a;
}

nav {
    position: relative;
    z-index: 1;
}

.header-top__wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 200px;
}

.header-top__wrap a {
    text-decoration: none;
    width: max-content;
}

.header-top__wrap > a {
    display: block;
    position: relative;
    left: 15px;
}

.phone__wrap > div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: end;
}

.phone__wrap a {
    color: #1d3f87;
    font-size: 28px;
}

#phone-mobile {
    display: none;
}

.header-top-donate__wrap {
    position: relative;
    width: 215px;
}

.header-top-donate__wrap div {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 12px 50px;
    overflow: hidden;
}

.header-top-donate__wrap div::before {
    content: "";
    background-image: url(/src/media/img/sun.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 47px;
    height: 40px;
    transform: rotate(156deg);
    position: absolute;
    left: -5px;
    top: -2px;
    transition: 0.25s ease-out;
    opacity: 0;
}

.header-top-donate__wrap div:hover::before {
    opacity: 1;
    transition: 0.2s ease-in;
}

.btn-sbp {
    background-color: #1d1346;
}

.header-top-donate__wrap div:hover {
    text-shadow: 3px 2px 2px #000;
    background-color: #60c8e6;
    border-color: #5bc0de;
    transition: 0.2s ease-in;
}

.navbar-default .navbar-collapse {
    padding: 0px;
}

.navbar-default {
    background-color: #fff;
    border-color: #fff;
}

.navbar-default .navbar-nav {
    transform: translateX(-50%);
    left: 50%;
    position: relative;
}

.navbar-default .navbar-nav > li > a {
    color: #6c6c6c;
    font-size: 20px;
    font-weight: 400;
    padding: 15px 38px;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
    color: rgb(30, 76, 154);
    background-color: #fff;
}

.navbar-default .navbar-nav > .active > a {
    color: rgb(30, 76, 154);
    background-color: #fff;
}

.navbar {
    margin-bottom: 0px;
}

#logo__a img {
    width: 215px;
}

#wrapper {
    z-index: 1;
    position: relative;
    margin: 100px auto -52px;
}

/* articles */
.articles-wrap {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    grid-gap: 20px;
    margin-top: 43px;
}

.articles-wrap a {
    color: initial;
}

.articles-wrap a:hover,
.articles-wrap a:focus {
    color: initial;
    text-decoration: initial;
}

.article-item {
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.39603px);
    height: 491px;
    border-radius: 4px;
}

.article-item__text {
    padding: 10px;
}

.article-item__title h2 {
    margin-top: 5px;
    font-size: 20px;
    font-weight: 600;
}

.article-item__description {
    /*height: 147px;*/
}

.article-item__description-diagnosis {
    height: 40px;
    overflow-y: auto;
}

.article-item__description-text {
    height: 92px;
    overflow-y: auto;
}

.article-item__img {
    height: 173px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.article-item__img img {
    height: 100%;
}

.article-item img {
    width: 100%;
}

.article-item__footer {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.article-item__money {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
}

.article-item__money p {
    margin: 0;
    padding: 3px;
}

.article-item__money > span > p:first-child {
    font-weight: bold;
}

.article-item__money > span:nth-child(2) {
    justify-self: end;
}

.article-item__money > span:nth-child(2) > p:nth-child(1) {
    text-align: end;
}

.article-item__money-progress {
    opacity: 0;
    font-weight: bold;
    position: absolute;
    top: 0px;
}

.article-item__progress-bar {
    background: #38a54a94;
    max-width: 100% !important;
    position: absolute;
    height: 7px;
    top: 50%;
    transform: translateY(-50%);
}

.article-item__money:hover .article-item__money-progress,
.article-item__money:focus .article-item__money-progress {
    opacity: 1;
    transition: 0.12s ease-in;
}

.article-item__money-recd-wrap {
    opacity: 1;
    transition: 0.12s ease-out;
}

.article-item__money:hover .article-item__money-recd-wrap,
.article-item__money:focus .article-item__money-recd-wrap {
    opacity: 0;
}

.article-item-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 9px;
    padding: 8px 10px;
    align-items: center;
    background-color: #5bc0de;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.article-btn-details {
    width: 100%;
}

.article-btn-details:hover {
    width: 100%;
    background-color: #5bc0de;
    border-color: #5bc0de;
    color: #000;
}

.article-donate {
    color: #000;
    font-weight: 600;
    background-color: #8bcb96;
    border-color: #8bcb96;
    line-height: 2;
    width: 100%;
}

.article-donate:hover {
    color: #000;
    background-color: #50c765;
    border-color: #50c765;
}

.article-item-buttons .article-donate {
    background-color: #fff;
    border-color: #fff;
}

.article-item-buttons .article-donate:hover {
    background-color: #fff;
    border-color: #fff;
    color: #5bc0de;
}

.article-link {
    padding: 20px;
    border: 1px solid #337ab7;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-left: auto;
    border-radius: 5px;
    margin-top: 20px;
}

.article-link:hover {
    opacity: 0.5;
    color: #23527c;
}

.choose-donate-btn {
    margin-top: 40px;
}

/* end articles */

.contacts-social {
    padding-top: 20px;
}

.contacts-social__title {
    margin: 0 0 10px;
}

.contacts-social__list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
}

.contacts-social__list-item {
    margin-right: 5px;
}

/* footer */
footer {
    height: initial;
}

.App-footer {
    margin-top: 40px;
    background-image: url(/src/media/img/footer.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.Footer-root {
    position: relative;
    /*z-index: 300;*/
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0 3px 12px;
    text-align: center;
    color: #999;
    font-family: Proxima Nova, Arial, Helvetica Neue, sans-serif;
    backdrop-filter: blur(8.39603px);
}

.Footer-container {
    width: 100%;
    max-width: 675px;
}

.Footer-nav {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.Footer-expanded .Footer-nav {
    flex-flow: column wrap;
    max-width: 295px;
    margin: 0 auto 20px;
}

.Footer-group {
    width: 100%;
    margin-bottom: 18px;
}

.Footer-groupTitle {
    margin-bottom: 4px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: hsla(0, 0%, 100%, 0.5);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-shadow: 0 0 4px #000;
}

.Footer-groupList {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 14px;
    line-height: 16px;
}

.Footer-groupList li {
    padding: 0 9px;
}

.Footer-groupList a {
    display: block;
    padding: 5px 0 2px;
    color: #fff;
    text-decoration: none;
    text-shadow: 0 0 3px #000;
}

.Footer-groupList a:hover {
    color: hsla(0, 0%, 100%, 0.5);
    text-shadow: 0 0 4px #000;
}

.Footer-copyright {
    padding: 1px 12px 0;
    text-align: left;
    font-size: 14px;
}

.Footer-copyrightGroup {
    display: flex;
    flex-flow: row nowrap;
}

.Footer-copyrightItem {
    width: 100%;
    color: #fff;
    text-shadow: 0 0 3px #000;
}

.Footer-copyrightItem:after {
    content: "";
    display: block;
    height: 18px;
    margin: 0 15px;
    border-left: 1px solid #fff;
}

.Footer-about {
    flex-shrink: 0;
    text-align: right;
}

.reg-code {
    color: #fff;
}

.Link-root {
    color: #fff;
    text-decoration: none;
    text-shadow: 0 0 3px #000;
}

.Link-root:hover {
    color: hsla(0, 0%, 100%, 0.5);
    text-decoration: none;
    text-shadow: 0 0 4px #000;
}

#last-year {
    margin: 0px 5px;
    white-space: nowrap;
}

button#subscriber-send {
    margin-top: 10px;
}

/* end footer */

.pages-content img {
    max-width: 100%;
}

/*header*/
.button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 3.75rem;
    border-radius: 0.375rem;
    border: none;
    background-color: transparent;
    transition: background-color 0.3s ease;
    cursor: pointer;
    text-align: center;
}

.button--need-help:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border-radius: 0.375rem;
    box-shadow: 0 0.3125rem 1.25rem 0 hsla(0, 0%, 100%, 0);
    transition: box-shadow 0.3s ease, top 0.3s ease, bottom 0.3s ease;
}

.header-phone__wrap {
    margin-top: 15px;
}

.header-phone-mobile {
    display: none;
}

.header-phone-mobile img {
    width: 50px;
    height: auto;
}

.header-phone-desktop {
    color: #fff;
    font-size: 0.9em;
    display: grid;
    justify-items: end;
    /* line-height: 12px; */
}

.header-phone-desktop span {
    font-size: 1.7em;
}

/*header*/

/* main screen */
.main-screen {
    min-height: 45rem;
    max-height: 53.125rem;
    height: 100vh;
    padding-top: 0;
    margin-bottom: -10rem;
}

.main-screen__background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
}

.main-screen__back-desktop {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}

.main-screen__back-desktop:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: rgba(0, 0, 0, 0.2);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.33) 60%,
      #ffffff 80%
    ); background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0, 0, 0, 0.2) 50%, #ffffff 94%); */
}

.main-screen__back-desktop video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    min-width: 100%;
    min-height: 100%;
}

.main-screen__wrapper {
    height: 100%;
    padding-left: 3.75rem;
    padding-right: 3.75rem;
    position: relative;
}

.main-screen__content {
    position: absolute;
    bottom: 16.5625rem;
}

.main-screen__title {
    font-size: 8rem;
    line-height: 8rem;
    margin-bottom: 2.5rem;
    color: #fff;
    font-weight: 700;
}

.main-screen__title span {
    color: #5bc0de;
}

.main-screen__link {
    position: relative;
    perspective: 1000px;
}

.main-screen__link:before {
    width: calc(100% + 7rem);
    left: -1.25rem;
    height: calc(100% + 2.5rem);
    background: hsla(0, 0%, 100%, 0.3);
    content: "";
    position: absolute;
    bottom: -1.1rem;
    border-radius: 0.3125rem;
    backface-visibility: hidden;
    opacity: 0;
    perspective-origin: 50% 50%;
    transform: rotateX(45deg);
    transition: opacity 0.2s ease, transform 0.3s ease 0s;
    transform-origin: 50% 50%;
    transform-style: preserve-3d;
}

.main-screen__link:hover:before {
    opacity: 1;
    transform: rotateX(0);
    transition: opacity 0.2s ease 0s, transform 0.3s ease 0s;
}

.main-screen__link-text {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.75rem;
    margin-right: 1.25rem;
    color: #fff;
}

.main-screen__link-text::after {
    content: "";
    margin-left: 25px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 3.4rem;
    height: 1.5rem;
    background-image: url(/src/media/img/arrow-right.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.header-toggle-menu-wrap {
    display: none;
}

/* main screen */

.breadcrumb a:hover {
    color: #337ab7;
    opacity: 0.8;
}

/* gallery */
.gallery-index {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(228px, 227px));
}

.gallery-index > a {
    color: #5bc0de;
    font-size: 8rem;
    line-height: 8rem;
    font-weight: 700;
    transition: 0.15s ease-out;
}

.gallery-index > a:hover {
    opacity: 0.85;
    color: #5bc0de;
    transition: 0.12s ease-in;
}

.album-wrap {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(228px, 227px));
    grid-row-gap: 20px;
}

.album-wrap > a {
    transition: 0.15s ease-out;
}

.album-wrap > a:hover {
    opacity: 0.95;
    transition: 0.12s ease-in;
}

.album-wrap > div {
    margin: 1rem;
}

/* gallery */

.page-not-found {
    padding: 50px;
    text-align: center;
}

.page-not-found p {
    margin: 0 0 20px;
}

.page-not-found__title {
    font-size: 40px;
    line-height: 8rem;
    margin-bottom: 2.5rem;
    color: #5bc0de;
    font-weight: 700;
}

.page-not-found a {
    color: #5bc0de;
    text-decoration: none;
}

@media only screen and (min-width: 12em) {
    .Footer-container {
        max-width: 970px;
    }

    .Footer-expanded .Footer-nav {
        height: 400px;
        max-width: none;
        margin-bottom: 0;
    }

    .Footer-expanded .Footer-group {
        width: 190px;
        margin-bottom: 33px;
        margin-left: 10px;
    }

    .Footer-expanded .Footer-group:first-child,
    .Footer-expanded .Footer-group:nth-child(2) {
        width: 170px;
        margin-left: 0;
    }

    .Footer-expanded .Footer-groupList {
        display: block;
    }

    .Footer-expanded .Footer-groupList li {
        padding: 0;
    }

    .Footer-expanded .Footer-groupList a {
        padding: 5px 0;
    }

    .Footer-copyright {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        padding: 13px 0 0;
        border-top: 1px solid #fff;
        font-size: 14px;
        line-height: 16px;
    }

    .Footer-copyrightItem {
        display: flex;
        flex-flow: row nowrap;
        width: auto;
    }

    .Footer-about {
        text-align: left;
    }

    .App-footer {
        margin-top: 64px;
    }

    .Footer-root {
        justify-content: flex-start;
        padding: 26px 0 15px;
        text-align: left;
    }
}

@media (max-width: 1026px) {
    .main-screen__back-desktop {
        display: none;
    }

    .main-screen__title {
        font-size: 4.5rem;
        line-height: 4.5rem;
    }

    .main-screen__content {
        bottom: 30rem;
    }
}

@media (max-width: 923px) {
    .header__nav-holder {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        transition: 0.27s ease-out opacity;
        background-color: rgba(0, 0, 0, 0.82);
        z-index: -1;
        opacity: 0;
        transform: translateY(-100%);
    }

    .header__nav-holder__active {
        transform: translateY(0);
        opacity: 1;
        z-index: 1001;
        transition: 0.32s ease-in opacity;
        overflow: auto;
    }

    .header-toggle-menu span {
        width: 33px;
        border: 2px solid #fff;
        display: block;
        border-radius: 150px;
        margin-top: 7px;
        transition: 0.3s;
    }

    .header-toggle-menu.rotate span:nth-child(1) {
        transform: rotate(45deg) translate(8px, 8px);
    }

    .header-toggle-menu.rotate span:nth-child(2) {
        transform: translate(80px, 0px);
    }

    .header-toggle-menu.rotate span:nth-child(3) {
        transform: rotate(135deg) translate(-7px, 8px);
    }

    .header__nav-wrapper {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
    }

    .header__nav-list {
        flex-direction: column;
        padding-left: 0px;
    }

    .header__buttons {
        display: grid;
        height: 140px;
    }

    .header-top-donate__wrap div {
        position: initial;
        right: initial;
        top: initial;
        transform: initial;
    }

    .header-toggle-menu-wrap {
        display: block;
    }

    .header-toggle-menu {
        position: absolute;
        top: 15px;
        right: 11px;
        width: 50px;
        height: 50px;
        border-radius: 100px;
        font-size: 7rem;
        line-height: 5rem;
        cursor: pointer;
        user-select: none;
        transition: 0.25s ease-out;
        z-index: 1002;
    }

    .header-toggle-menu-stroke {
        background-color: #5bc0de;
        position: absolute;
        top: 10px;
        right: 20px;
        border-radius: 100px;
        width: 50px;
        height: 50px;
        transition: 0.35s ease-out;
        z-index: 1000;
    }

    .scale-50 {
        transition: 0.21s ease-in;
        transform: scale(50);
    }

    .z-index-2 {
        position: relative;
        z-index: 2;
    }

    .overflow-hidden {
        overflow: hidden;
    }

    .hide-z {
        z-index: -1;
        opacity: 0;
        transition: 0.24s ease-out;
    }

    .sun-wrap {
        margin: 37px;
        position: absolute;
        left: 0;
        top: 0;
    }

    .sun {
        width: 36px;
        height: 35px;
        position: absolute;
        left: 0;
        top: 0;
        background-image: url(/src/media/img/sun.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        transform: rotate(0);
        transition: 0.28s ease-in;
    }

    .sun-move {
        z-index: 1000;
        transform: rotate(180deg);
        transition: 0.24s ease-out;
    }
}

@media (max-width: 767px) {
    .Footer-expanded .Footer-nav {
        display: grid;
        grid-template-columns: 1fr;
        height: initial;
        justify-items: center;
    }

    #phone-mobile {
        display: block;
    }

    #phone-mobile > a > img {
        width: 55px;
        height: 55px;
    }

    #phone-desktop {
        display: none;
    }

    body > header > div.header-top__wrap > a > img {
        height: 60px;
    }

    .main-screen__title {
        font-size: 2.7rem;
        line-height: 2.7rem;
    }

    .main-screen__link-text {
        font-size: 2rem;
    }
}

@media (max-width: 500px) {
    .header-top-donate__wrap div {
        padding: 12px 34px;
    }

    .Footer-copyrightGroup {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
    }

    .Footer-copyrightItem {
        margin-bottom: 10px;
    }

    .Footer-copyrightItem:after {
        content: none;
    }
}

@media (max-width: 405px) {
    .header-top-donate__wrap div {
        padding: 12px 26px;
    }
}

@media (max-width: 380px) {
    .header-top-donate__wrap div {
        padding: 12px 18px;
    }

    .main-screen__title {
        font-size: 2.4rem;
        line-height: 2.4rem;
    }

    .main-screen__link-text {
        font-size: 1.8rem;
    }
}

.members-wrapper p {
    margin: 0;
}

h1 {
    margin-top: 5px;
}

.pages-wrap {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-gap: 32px;
}

.sidebar {
    list-style-type: none;
    padding: 0;
}

li.sidebar-item {
    border-top: 1px solid #dbdcdd;
    font-size: 16px;
    line-height: 18px;
}

li.sidebar-item:first-child {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
}

li.sidebar-item a {
    display: block;
    padding: 15px 0;
    color: #000;
    text-decoration: none;
}

@media (max-width: 460px) {
    .pages-wrap {
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }

    .sidebar {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 0;
    }

    .sidebar a:not(:last-child) {
        margin: 0 1px 1px 0;
    }

    .ChronologyItem-link h2 {
        font-size: 22px;
    }
}

.pages-content {
    overflow: hidden;
}

.pages-content * {
    grid-gap: 1px;
}

.report-btns {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.report-btn {
    border-radius: 0px;
}

.report-btns div:first-child {
    border-top-left-radius: 4px;
}

.report-btns div:last-child {
    border-top-right-radius: 4px;
}

.donate-btn {
    width: 100%;
}

.report-right {
    justify-self: right;
}

.report-box {
    margin: 10px 0px;
}

.report-box-item,
.report-content-past-year,
.past-year-month-btns-container,
.report-content-past-year-month,
.report-btn-current-year-month,
.report-content-current-year-month {
    display: none;
    margin: 1px;
}

.report-active {
    display: block !important;
}

/*.pages-text */
.pages-text__title {
    margin: 0 0 40px;
}

.pages-text__pay-sber-title {
    margin: 0 0 30px;
}

.pages-text__pay-sber-description {
    margin: 0 0 100px;
}

.pages-text__pay-sber-instruction {
    margin: 0 0 20px;
}

/*.pages-text */

.report-content-past-year,
.report-content-current-year {
    overflow-x: auto;
}

table > tbody > tr {
    background-color: #f5f5f5;
}

table > tbody > tr:nth-child(odd) {
    background-color: #d9d9d9;
}

table > thead > tr {
    color: #fff;
    background-color: #8a8a8a;
    white-space: normal;
}

table > thead > tr > th {
    padding: 1rem;
    font-weight: normal;
}

table > tbody > tr > td {
    padding: 5px 10px;
}

.past-year-btns-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
}

.past-year-month-btns-container-wrap,
.current-year-month-btns-container-wrap {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(142px, 1fr));
}

.past-year-content-container,
.past-year-month-btns-container,
.current-year-month-btns-container {
    margin: 10px 0px;
}

.past-year-content-container p {
    margin: 10px 0 0 0;
    font-size: 1.15em;
    font-weight: 500;
    font-style: italic;
}

.active-year-content {
    display: block;
}

.sliderAppeal {
    display: flex;
}

.sliderAppealItem {
    box-shadow: 2px 6px 14px rgb(0 0 0 / 13%), 1px -4px 9px rgb(0 0 0 / 12%);
    border-radius: 5px;
    margin: 10px 10px 20px;
    padding: 15px;
    align-self: stretch;
}

.sliderAppealItemImg {
    margin-bottom: 20px;
}

.sliderAppealItemName {
    font-weight: 700;
}

.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #5bc0de;
    font-size: 20px;
}

.sliderAppeal .slick-prev:before,
.sliderAppeal .slick-next:before,
.sliderNews .slick-prev:before,
.sliderNews .slick-next:before {
    color: #e9e9e9;
    font-size: 40px;
}

.sliderAppeal .slick-prev,
.sliderAppeal .slick-next,
.sliderNews .slick-prev,
.sliderNews .slick-next {
    top: -25px;
    /*bottom: -60px;*/
    width: 40px;
    height: 40px;
    z-index: 1;
}

.sliderAppeal .slick-next {
    right: 10%;
}

.sliderAppeal .slick-prev {
    left: 10%;
}

.Chronology-wrapper {
    max-width: 825px;
    margin: 0 auto;
}

.pages-wrap {
    grid-template-columns: 1fr !important;
}

.pages-content {
    padding: 12px 10px 10px;
    border-radius: 10px;
    background-color: #f2f2f2;
}

.pages-sidebar {
    height: max-content;
}

.sidebar {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 1px;
}

.sidebar a {
    border-radius: 0;
}

.sidebar a:first-child {
    border-top-left-radius: 4px;
}

.sidebar a:last-child {
    border-top-right-radius: 4px;
}

.Chronology-item {
    margin: 12px 0;
}

.ChronologyItem-link {
    display: block;
}

a:hover,
a:focus {
    text-decoration: none;
    opacity: 0.95;
}

.ChronologyItem-root {
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    color: #000;
    background-color: #fff;
    font-family: PF Regal, PF Regal Text Pro, Georgia, serif;
    -webkit-tap-highlight-color: transparent;
}

.ChronologyItem-figure {
    width: 100%;
    min-height: 160px;
    padding-top: 66.66%;
    position: relative;
    margin: 0;
    border-radius: 8px 8px 0 0;
}

.ChronologyItem-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 8px 8px 0 0;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
}

.ChronologyItem-body {
    display: flex;
    flex-flow: column nowrap;
}

.ChronologyItem-header {
    margin-bottom: 8px;
}

.ChronologyItem-footer {
    margin-top: auto;
    color: gray;
    font-size: 12px;
    line-height: 1;
    fill: #b88b58;
}

@media only screen and (min-width: 32em) {
    .Chronology-container {
        padding: 25px 10px 45px;
    }

    .ChronologyItem-root {
        flex-direction: row;
    }

    .ChronologyItem-figure {
        width: 33.333%;
        min-height: 200px;
        border-radius: 8px 0 0 8px;
        padding-top: 0;
    }

    .ChronologyItem-image {
        border-radius: 8px 0 0 8px;
    }

    .ChronologyItem-body {
        padding: 18px 20px;
        max-width: 66.66%;
    }

    .ChronologyItem-header {
        margin-bottom: 18px;
    }
}

@media only screen and (max-width: 32em) {
    .Chronology-container {
        padding: 25px 10px 45px;
    }

    .ChronologyItem-image {
        border-radius: 8px 8px 0 0;
    }

    .ChronologyItem-body {
        padding: 18px 20px;
    }

    .ChronologyItem-header {
        margin-bottom: 18px;
    }

    .MetaItem-date {
        display: grid;
        justify-items: end;
    }
}

@media only screen and (max-width: 992px) {
    .pages-wrap {
        grid-template-columns: 1fr !important;
    }
}

#wrapper {
    margin: 100px auto -52px;
}

.navbar {
    border-radius: 0px;
    margin-bottom: 20px;
}

.articles {
    display: grid;
}

.articles.articles-main {
    margin-top: 65px;
}

.articles > .pagination {
    justify-self: end;
}

#w0 {
    height: 360px;
    overflow-y: hidden;
    margin-top: -20px;
}

#w0 .carousel-inner,
#w0 .carousel-inner .item {
    height: inherit;
}

#w0 > div > div img {
    transform: translateX(-50%);
    left: 50%;
    position: relative;
}

#w0 .item a {
    display: block;
}

.carousel-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.carousel-indicators {
    width: initial;
}

.carousel-caption {
    text-align: initial;
    width: max-content;
    backdrop-filter: blur(8.39603px);
}

.carousel-control {
    display: none;
}

.sidebar-pages {
    list-style-type: none;
    padding: 0;
}

.pages-wrap-pages {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-gap: 32px;
}

@media (max-width: 460px) {
    .pages-wrap-pages {
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }
}

/* strong {
  padding-left: 5px;
  padding-right: 5px;
} */

.pages-main-content {
    overflow: hidden;
}

.pages-main-content img {
    max-width: 100%;
}

.slider-sovet > div > div {
    margin: 10px 10px 20px;
}

.slider-sovet > div > div > div > div {
    padding: 2rem;
}

.slider-sovet > div > div > div > div > div {
    box-shadow: 2px 6px 14px rgb(0 0 0 / 13%), 1px -4px 9px rgb(0 0 0 / 12%);
    border-radius: 5px;
    padding: 15px;
    align-self: stretch;
}

/* Helpman */
.helpman-wrap {
    margin-top: 20px;
}

.helpman {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    grid-gap: 21px;
    align-items: center;
    justify-items: center;
}

.helpman > a,
.helpman > .adv-item {
    height: 100%;
    width: 100%;
    color: #000;
    transition: ease-out 0.1s;
}

.helpman > a:hover,
.helpman > a:focus {
    color: #000;
    text-decoration: none;
}

.helpman > a:hover,
.helpman > .adv-item:hover {
    transform: scale(1.1);
    transition: ease-in 0.1s;
}

.adv-item {
    padding: 35px 20px 25px;
    background: #fff;
    border: 1px solid #0000000f;
    border-radius: 4px;
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.helpman > a:hover::after {
    content: "";
    display: block;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 14px;
    height: 14px;
    background-image: url(../src/media/img/target_blank.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.adv-item img {
    width: 120px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.adv-item h3 {
    font-size: 18px;
}

@media (max-width: 767px) {
    .adv-item h3 {
        font-size: 20px;
    }
}

/* END Heplman */

/* LOADER */

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #cef;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #cef transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* END LOADER */

/* Loader Promotions */

.loading-promotion {
    background: linear-gradient(90deg, #eee, #ccc, #eee);
    height: inherit;
    margin: 1rem;
    border-radius: 10px;
    animation: loading-promotion 2s infinite alternate;
    transition: all 2s ease-in-out;
}

@keyframes loading-promotion {
    100% {
        background-position: 80vw;
    }
}

.fadeIn {
    animation: fadeIn 0.5s normal linear;
    animation-fill-mode: forwards;
}

.opacity__null {
    opacity: 0;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* END Loader Promotions */

/* Pagination loading */
.pagination__loading {
    margin: 20px 0;
}

.pagination__loading div {
    height: 35px;
    background: linear-gradient(90deg, #eee, #ccc, #eee);
    animation: loading-promotion 2s infinite alternate;
}

/* END Pagination loading */

/* News Loader */
.Chronology-item .loading-promotion {
    min-height: 200px;
}

/* END News Loader */

/* NEWS INSIDE */
.news-inside-wrapper {
    margin-bottom: 40px;
}

.news-inside-title {
    margin-top: 30px;
}

.news-inside-title-image {
    margin-top: 30px;
    max-width: 100%;
}

.news-inside-text {
    margin-top: 25px;
}

.news-inside-text iframe {
    max-width: 100% !important;
}

.news-inside-text img {
    max-width: 100%;
}

.news-inside-text a:hover {
    color: #000;
}

.news-inside-photos {
    margin-top: 30px;
}

.news-inside-photos img {
    margin: 2px 0px;
}

/* END NEWS INSIDE */

/* Animate for main title */
.main-screen__content {
    animation: smooth-appearance 1.5s normal linear;
}

@keyframes smooth-appearance {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Animate for main title */

.articles-btns {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 1px;
}

.articles-btn active {
    cursor: context-menu;
}

.articles-btns.btn,
.articles-btns > a > .btn {
    border-radius: 0px;
}

.articles-btns > a:first-child > div,
.articles-btns > div:first-child {
    border-radius: 0px;
    border-top-left-radius: 4px;
}

.articles-btns > a:last-child > div,
.articles-btns > div:last-child {
    border-radius: 0px;
    border-top-right-radius: 4px;
}

.card-wrap {
    display: grid;
    grid-template-columns: 223px 150px 1fr;
    grid-gap: 20px;
}

.article-item__img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    object-fit: cover;
    object-position: center;
}

.card-btns {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1px;
}

.card-btn {
    border-radius: 0px;
}

.card-btns div:first-child {
    border-top-left-radius: 4px;
}

.card-btns div:last-child {
    border-top-right-radius: 4px;
}

.donate-btn {
    width: 100%;
}

.card-right {
    justify-self: right;
}

.card-box {
    margin: 10px 0px;
    min-height: 150px;
}

.card-box-item {
    display: none;
}

.card-active {
    display: block !important;
}

.card-report > table > tbody > tr {
    background-color: #f5f5f5;
}

.card-report > table > tbody > tr:first-child {
    color: #fff;
    background: #8a8a8a;
}

.card-report > table > tbody > tr > td {
    padding: 5px 10px;
}

.card-left h1 {
    font-size: 28px;
}

.card-active {
    padding-right: 20px;
}

.card-left-photo {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.breadcrumb {
    margin-right: -130px;
}

.photoItem {
    margin: 5px;
    max-width: 45%;
}

.card-general-information h1 img {
    width: 100% !important;
}

@media (max-width: 991px) {
    .card-wrap {
        grid-template-columns: 1fr 0.65fr;
    }

    .card-right {
        width: initial;
        justify-self: initial;
        grid-column: 1/3;
    }

    .card-general-information.card-box-item.card-active img {
        width: 100% !important;
    }

    .breadcrumb {
        display: flex;
        flex-direction: column;
        max-width: 100%;
    }
}

@media (max-width: 575px) {
    .card-btns {
        grid-template-columns: 1fr;
    }

    .card-btns div:first-child {
        border-top-right-radius: 4px;
    }

    .card-btns div:last-child {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .card-box {
        width: calc(100vw - 30px);
        overflow: hidden;
    }

    .card-report {
        overflow-x: auto;
    }
}

@media (max-width: 450px) {
    .card-wrap {
        grid-template-columns: 1fr;
    }

    .card-right {
        grid-column: initial;
    }
}

.card-medical {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.card-medical__item {
    max-width: 45%;
    margin: 1rem;
}

.inside-wrapper {
    text-align: center;
}

table {
    display: block;
    overflow-x: auto;
}

.form-group--checkbox {
    display: flex;
    flex-direction: column;
}

.form-group--checkbox .field-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.form-group--checkbox .form-control {
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
}

.form-group--checkbox .reccurent-pay-note {
    flex-grow: 1;
}

.form-group--checkbox .reccurent-cancel-link:hover,
.form-group--checkbox .reccurent-cancel-link:focus {
    color: #23527c;
}

.form-group--checkbox .control-label {
    margin: 0;
    padding-top: 2px;
    line-height: 18px;
}

/* POPUP STYLES */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.4s linear 0s, opacity .4s linear;
}

.popup_opened {
    visibility: visible;
    transition: visibility 0.2s, opacity 0.2s ease-in-out;
    opacity: 1;

}

.popup__content {
    max-width: 430px;
    width: 100%;
    height: 260px;
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    font-family: "Inter", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.popup__image {
    max-width: 75vw;
    max-height: 75vh;
}

.popup-image__close {
    position: absolute;
    right: -38px;
    top: -38px;
    background-image: url(./media/img/close.svg);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    width: 24px;
    height: 24px;
}


@media (max-width: 530px) {
    .popup__content {
        max-width: 320px;
    }
}

@media (max-width: 320px) {
    .popup__content {
        max-width: 240px;
    }
}

.popup__close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    background-image: url(./media/img/close.svg);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.popup__close:hover {
    opacity: 0.2;
}

.popup__button {
    width: 260px;
}

/* POPUP STYLES */

/* SWIPER STYLES */

.swiper-container {
    max-height: 100%;
    margin-top: 70px;
    vertical-align: center;
}

.swiper-wrapper {
    align-items: center;
}

.swiper-slide {
    height: 300px;
}

.swiper-slide img {
    width: auto;
    height: 100%;
}

/* SWIPER STYLES */

/* popup children */
.popupChildren__content {
    width: 844px;
    height: 531px;
    position: relative;
    display: flex;
    flex-direction: row;
    font-family: "Mont", sans-serif;
    background: url("./media/img/popupChildren-background.png")  #fff no-repeat;
}

.popupChildren__content p {
    margin: 0;
}

.popupChildren__close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 21px;
    height: 21px;
    cursor: pointer;
}

.popupChildren__close:hover {
    opacity: 0.5;
}

.popupChildren__text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px 20px 533px;
}

.popupChildren__img {
}

.popupChildren__title {
    color: #2E4196;
    font-size: 35px;
    font-weight: 700;
    line-height: 122.5%;
    margin: 62px 0 73px 0;
}

.popupChildren__description-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 90px;
}

.popupChildren__description {
    color: #2E4196;
    font-size: 22px;
    font-weight: 500;
    line-height: 122.5%;
}

.popupChildren__description_bold {
    color: #2F4389;
    font-size: 25px;
    font-weight: 800;
    line-height: 140%;
    letter-spacing: 1.5px;
}

.popupChildren__button-container {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.popupChildren__button {
    position: relative;
    width: 206px;
    height: 35px;
    border-radius: 9px;
    border: none;
    padding: 0;
    background-color: #A7C6EC;
    box-shadow: 0px 4px 11px 0px rgba(16, 65, 139, 0.00);
    color: #2E4196;
    font-size: 22px;
    font-weight: 500;
    line-height: 102%;
    margin-bottom: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
}

.popupChildren__heart-img {
    position: absolute;
    right: -11px;
    top: -15px;
}

.popupChildren__text_thin {
    color: #2E4196;
    font-size: 14px;
    font-weight: 500;
    line-height: 122.5%;
    cursor: pointer;
    transition: 0.2s;
}

.popupChildren__text_thin:hover {
    opacity: 0.5;
}

@media screen and (max-width: 900px) {
    .popupChildren__content {
        /*width: 90%;*/
        /*height: 80%;*/
        width: 350px;
        height: 650px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 3%;
        background-size: auto 45%;
        background-position-x: 25px;
    }

    .popupChildren__text-container {
      padding: 200px 0 0 0;
    }

    .popupChildren__img {
        width: 70%;
        height: auto;
        top: 110px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    .popupChildren__title {
        margin: 0;
        font-size: 26px;
    }
    .popupChildren__description {
        font-size: 16px;
    }

    .popupChildren__description_bold {
        font-size: 18px;
    }

    .popupChildren__text-container {
        margin-right: 0;
        text-align: center;
        gap: 20px;
    }

    .popupChildren__description-container {
        margin-bottom: 15px;
    }

    .popupChildren__button-container {
      align-self: center;
    }

    .popupChildren__button {
        height: 40px;
        font-size: 22px;
    }
}


/* popup children */

/* popup gratitude */

.gratitude__button {
  width: 100%;
  height: 100%;
  border: none;
  padding: 0;
}

.popup-gratitude__container {
  height: 90vh;
  position: relative;
}

.popup-gratitude__image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.popup-gratitude__close-button {
  position: absolute;
  top: 0;
  right: -50px;
  width: 40px;
  height: 40px;
  border: none;
  padding: 0;
  background: url("../src/media/img/close_white.svg") center;
  background-size: cover;
}

@media screen and (max-width: 900px) {
  .popup-gratitude__container {
    height: 70vh;
    width: 100%;
    padding: 0 20px;
    position: relative;
  }

  .popup-gratitude__close-button {
    top: -35px;
    right: 5%;
    width: 30px;
    height: 30px;
  }
}

/* popup gratitude */

/* tables */
.tables__cell {
  width: 15%;
}
/* tables */
