.mobileMenu {
    position: fixed;
    left: 0;
    top: 0;
    background-color: #102329;
    width: 100%;
    height: 100%;
    z-index: 400;
    visibility: hidden;
}

.mobileMenu_opened {
    visibility: visible;
}

.mobileMenu__content {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 300px;
}

.mobileMenu__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    gap: 20px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 19px;
    line-height: 110%;
    text-align: center;
}

.mobileMenu__link {
    color: #FFFFFF;
}

.mobileMenu__link:hover {
    border-bottom: 2px solid #FFFFFF;
}

.mobileMenu__buttonsContainer {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.mobileMenu__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    color: #ffffff;
    font-size: 14px;
    line-height: 110%;
    padding: 14px 24px;
    border: none;
    border-radius: 4px;
    background-color: #1FBCEE;
}

.mobileMenu__button:before {
    content: "";
    background-image: url("../../media/img/sun.png");
    transform: rotate(156deg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    width: 47px;
    height: 40px;
    position: absolute;
    left: -5px;
    top: -2px;
    opacity: 0;
}

.mobileMenu__button:hover {
    text-shadow: 3px 2px 2px #000;
    background-color: #60c8e6;
    border-color: #5bc0de;
    transition: .2s ease-in;
}

.mobileMenu__button:hover:before {
    opacity: 1;
    transition: .2s ease-in;
}

.mobileMenu__infoContainer {
    margin-top: 40px;
    text-align: center;
    font-size: 20px;
    color: #ffffff;
}

.mobileMenu__close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.mobileMenu__close:hover {
    opacity: 0.5;
}