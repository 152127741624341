.header {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 800px;
}

.header__about {
    background-image: url("../../media/img/bannerAboutPc.jpg");
    height: 460px;
    background-position: center;
}

.header__theyNeedHelp {
    background-image: url("../../media/img/bannerTheyNeedHelpPc.jpg");
    height: 460px;
    background-position: center;
}

.header__news {
    background-image: url("../../media/img/bannerNewsPc.jpg");
    height: 460px;
    background-position: center;
}

.header__helpman {
    background-image: url("../../media/img/bannerTheyHelpUsPc.png");
    height: 460px;
    background-position: center;
}

.header__reports {
    background-image: url("../../media/img/bannerOtchetiPc.jpg");
    height: 460px;
    background-position: center;
}

.header__toHelp {
    background-image: url("../../media/img/bannerHelpPc.jpg");
    height: 460px;
    background-position: center;
}

.header__video {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -5;
}

.header__wrapper {
    padding: 40px 40px 0 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header__nav-list {
    display: flex;
    flex-direction: row;
    list-style: none;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 19px;
    line-height: 110%;
    gap: 20px;
}

.header__nav-link {
    color: #ffffff;
    padding: 0;
}

.header__nav-link_blue {
    color: #1FBCEE;
    padding: 0;
}

.header__nav-link_blue:hover, .header__nav-link_blue:focus {
    border-bottom: 2px solid #1FBCEE;
    color: #1FBCEE;
}

.header__nav-link:hover, .header__nav-link:focus {
    border-bottom: 2px solid #FFFFFF;
    color: #FFFFFF;
}

.header__info-wrapper {
    display: flex;
    flex-direction: column;
}

.header__buttons-container {
    align-self: flex-end;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.header__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    color: #ffffff;
    font-size: 14px;
    line-height: 110%;
    padding: 14px 24px;
    border: none;
    border-radius: 4px;
    background-color: #1FBCEE;
}

.header__phone {
    margin-top: 20px;
    align-self: flex-end;
    color: #1FBCEE;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
}

.header__text-container {
    align-self: flex-end;
    text-align: right;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
}

.header__text {
    margin: 0;
}

.header__title-wrapper {
    padding-left: 40px;
    margin-top: auto;
}

.header__title {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 80px;
    line-height: 110%;
    margin: 0 0 40px 0;
}

.header__button:before {
    content: "";
    background-image: url("../../media/img/sun.png");
    transform: rotate(156deg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    width: 47px;
    height: 40px;
    position: absolute;
    left: -20px;
    top: -2px;
    opacity: 0;
}

.header__button:hover {
    text-shadow: 3px 2px 2px #000;
    background-color: #60c8e6;
    border-color: #5bc0de;
    transition: .2s ease-in;
}

.header__button:hover:before {
    opacity: 1;
    transition: .2s ease-in;
}

.header__title-blue {
    color: #1FBCEE;
}

.header__title-white {
    color: #ffffff;
}

.header__title-link {
    color: #ffffff;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
    position: relative;
}

.header__title-link:after {
    content: url("../../media/img/arrow-right.svg");
    position: absolute;
    margin-left: 25px;
    width: 34px;
}

.header__title-link:hover {
    border-bottom: 2px solid #ffffff;
}

.header__logoSmall {
    width: 76px;
    height: auto;
}

.header__title-wrapperSmall {
    margin-top: -50px;
}

.header__burgerMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    gap: 10px;
    align-self: flex-end;
    cursor: pointer;
}

.header__burgerMenu:hover {
    opacity: 0.5;
}

@media screen and (max-width: 925px) {
    .header {
        height: 640px;
    }
    .header__about {
        background-image: url("../../media/img/bannerAboutMobile.jpg");
        height: 360px;
        background-position: center;
        background-size: cover;
    }

    .header__theyNeedHelp {
        background-image: url("../../media/img/bannerTheyNeedHelpMobile.jpg");
        height: 360px;
        background-position: center;
        background-size: cover;
    }

    .header__news {
        background-image: url("../../media/img/bannerNewsMobile.jpg");
        height: 360px;
        background-position: center;
        background-size: cover;
    }

    .header__helpman {
        background-image: url("../../media/img/bannerTheyHelpUsMobile.jpg");
        height: 360px;
        background-position: center;
        background-size: cover;
    }

    .header__reports {
        background-image: url("../../media/img/bannerReportsMobile.jpg");
        height: 360px;
        background-position: center;
        background-size: cover;
    }

    .header__toHelp {
        background-image: url("../../media/img/bannerHelpUsMobile.jpg");
        height: 360px;
        background-position: center;
        background-size: cover;
    }

    .header__buttons-container {
        display: none;
    }

    .header__title-wrapper {
        padding-left: 10px;
        margin-bottom: 40px;
    }

    .header__title {
        font-size: 28px;
        line-height: 130%;
    }

    .header__title-link {
        font-size: 18px;
        line-height: 110%;
    }

    .header__wrapper {
        padding: 10px 10px 0 10px;
    }

    .header__logo {
        width: 50px;
    }

    .header__title-wrapperSmall {
        margin-top: 50px;
    }
}